import { DOLLER_PRICE } from '../config/constants';
import { QUERY_KEYS } from '../hooks/hooksUtils';
import { useQueryData } from '../hooks/useQueryData';
import { useUserStore } from '../stores/UserStore';
import { RestService } from './RestService';

export const fetchPayments = () => {
  return RestService.get(`/payments`);
};

export const usePaymentsData = () => {
  const userUuid = useUserStore.getState().UUID;
  return useQueryData([QUERY_KEYS.PAYMENTS], fetchPayments, { enabled: !!userUuid });
};

export const priceConvertion = (price: number) => {
  return Math.round(parseInt(price * DOLLER_PRICE + '', 0) * 100);
};
